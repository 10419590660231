body {
    display: flex;
    justify-content: space-around;
    margin: 0;
}

body.cam {
    justify-content: flex-end;
}

#mainVideo {
    height: 100vh;
    width: calc(100vw - 50rem);
    position: fixed;
    top: 0;
    left: 0;
}

#mainApp {
    border: solid thin;
    box-sizing: border-box;
    width: 50rem;
    min-height: 100vh;
    position: relative;
    padding-bottom: 5rem;
}

#mainApp * {
    font-family: Verdana, sans-serif;
}

a {
    color: #0652DD;
}

a:visited {
    color: #1B1464;
}
/*
 * Jessica likes this blue: #6df, better than #1da1f2
 */

header {
    align-content: center;
    background-color: #555;
    border-bottom: solid thin;
    box-sizing: border-box;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
}

header h1 {
    font-size: 100%;
}

header h1 a,header h1 a:visited {
    color: white;
}

header h2 {
    font-size: 100%;
    font-weight: normal;
    height: 100%;
    margin: auto 0;
    color: inherit;
    padding: 0.1em 0 0.1em 0.3rem;
    text-align: center;
    min-width: 4rem;
}

header h2 button {
    padding: 0;
    margin-left: 0.5rem;
}

header h2 button img {
    height: 1rem;
}

main summary {
    cursor: pointer;
    margin: 1rem;
}

.middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 1rem;
}

button.delete {
    float: left;
    margin: 0 0 0 1.1rem;
}

main p {
    padding: 0 1rem;
}

main.cite input.wide {
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    width: 100%;
}

p.claim {
    text-align: center;
}

p.pub {
    text-align: right;
    font-style: italic;
}

input {
    font-size: large;
}
.claim input {
    box-sizing: border-box;
    font-size: large;
    font-weight: bold;
    color: #666;
    text-align: center;
    width: 100%;
}

main.empty .related, main:not(.debug) .debug {
    display: none;
}

.related > div {
    margin: 0.5rem;
    padding: 0.5rem;
}

.related h2 {
    font-size: 100%;
    margin: 0;
}

.related ul {
    list-style: none;
    margin: 0.25rem 0;
}

.related ul li {
    position: relative;
    line-height: 1.3rem;
}

.related ul li::before {
    content: '➌';
    position: absolute;
    left: -1.5em;
    text-align: center;
    width: 1.3rem;
}

.related ul li.t5::before {
    content: '➎';
    color: #009432;
}

.related ul li.t4::before {
    content: '➍';
    color: #0652DD;
}

.related ul li.t2::before {
    content: '➋';
    color: #F79F1F;
}

.related ul li.t1::before {
    content: '➊';
    color: #EA2027;
}

.related ul li input {
    box-sizing: border-box;
    width: 100%;
}

dl.ratings {
    border-bottom: solid thin;
    border-top: solid thin;
    display: flex;
    max-width: 100%;
    margin-bottom: 0;
}

dl.ratings > div {
    border-top: solid white;
    border-bottom: solid white;
    cursor: pointer;
    padding: 0.2rem;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

dl.ratings > div * {
    cursor: inherit;
}

dl.ratings > div:not(:last-of-type) {
    border-right: solid thin;
}

dl.ratings > div.owner {
    background-color: #ddd;
    color: black;
    border-top: solid #ddd;
    border-bottom: solid #ddd;
}

dl.ratings > div.user {
    border-top: solid #777;
    border-bottom: solid #777;
}

dl.ratings > div.user a.you {
    position: absolute;
}

dl.ratings > div:not(.owner) label.long {
    opacity: 0;
}

dl.ratings > div.selected label.long {
    opacity: initial;
}

dl.ratings > div.selected:not(:last-of-type) {
    border-right: solid thin;
}

dl.ratings input[type=radio] {
    position: absolute;
    opacity: 0;
}

dl.ratings dt, dl.ratings dd {
    text-align: center;
    margin: 0;
}

dl.ratings dt {
    font-size: xx-large;
}

dl.ratings dt.t1 {
    color: #EA2027;
}

dl.ratings dt.t2 {
    color: #F79F1F;
}

dl.ratings dt.t4 {
    color: #0652DD;
}

dl.ratings dt.t5 {
    color: #009432;
}

dl.ratings dd {
    align-content: center;
    display: flex;
    flex-direction: column;
    min-height: 55%;
    justify-content: space-between;
    hyphens: auto;
    line-height: 1.1rem;
}

div.peanut-gallery {
    border-bottom: solid thin;
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    max-width: 100%;
}

div.peanut-gallery span {
    text-align: center;
    width: 20%;
}

nav.toolbar {
    background-color: #aaa;
    display: flex;
    justify-content: space-between;
    min-height: 5rem;
    position: absolute;
    bottom: 0;
    width: 100%;
}

nav.toolbar .current {
    background-color: #ddd;
}

nav.toolbar > a {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em 1em 0.7em 1rem;
}

@media screen and (max-width: 768px) {

    header {
        padding: 0 0.25rem;
    }

    header h2 {
        padding: 0.1em 0 0.1em 0.25rem;
    }

    header h2 button {
        padding: 0;
        margin-left: 0.25rem;
    }

    header h2 button img {
        height: 1rem;
    }

    .middle {
        margin: 0.25rem;
    }

    button.delete {
        margin: 0 0 0 0.25rem;
    }

    main p {
        padding: 0 0.25rem;
    }

    .related > div {
        margin: 0;
        padding: 0.25rem;
    }
}
